<template>
    <div class="account-tilled-connect" v-if="user">
        <md-card style="max-width:1000px;margin:auto">
            <md-card-header>
                <h2>EzTimePay Connect</h2>
            </md-card-header>
            <md-card-content>
                <div v-if="!showConnect">
                    <label for>Connect Id:</label>
                    <span>{{ user.tilledConnectId }}</span>
                </div>
                <div class="connect-ctrls">
                    <md-button @click="jumpConnect" class="connect md-raised md-primary" v-if="showConnect">Connect EzTimePay</md-button>
                    <md-button class="conncet-success md-raised" v-if="!showConnect">Your account is connected with EzTimePay</md-button>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { vendorTypes } from "@/config";
export default {
    data() {
        return {
            vendorTypes
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            defaultHead: "user/defaultHead",
            paymentSetting: "setting/payment",
            error: "error",
            loading: "loading"
        }),

        showConnect() {
            return !this.user.tilledConnected && this.user.role == "vendor" && !this.user.master_vendor_id;
        },
        showReConnect() {
            let { showReConnect } = this.$route.query;
            return showReConnect;
        }
    },
    watch: {},
    methods: {
        ...mapMutations({
            setUser: "user/setUser"
        }),
        ...mapActions({
            updateProfile: "user/updateProfile"
        }),
        jumpConnect() {
            const { value } = this.paymentSetting;
            if (value && value.tilled_client_id) {
                if (!this.user.tilledConnectId) {
                    this.connect();
                } else if (this.user.tilledOnBoardingUrl) {
                    this.checkConnect();
                } else {
                    location.reload();
                }
            } else {
                this.$store.commit("setMessage", "Client Id not found");
            }
        },
        async connect(code) {
            let { data } = await this.$store.dispatch("crud/post", {
                api: "EzTimePay/connect"
            });
            const url = data.onboarding_application_url.indexOf("http") ? "https://" + data.onboarding_application_url : data.onboarding_application_url;
            this.setUser({ ...this.user, ...{ tilledConnectId: data.id, tilledOnBoardingUrl: url } });
            window.open(url);
        },
        async checkConnect() {
            let data = await this.$store.dispatch("crud/get", {
                api: `EzTimePay/account/check/${this.user.tilledConnectId}`
            });

            if (!data.connected) {
                const url = this.user.tilledOnBoardingUrl.indexOf("http") ? "https://" + this.user.tilledOnBoardingUrl : this.user.tilledOnBoardingUrl;
                window.open(url);
            } else {
                this.setUser({ ...this.user, ...{ tilledOnBoardingUrl: null, tilledConnected: true } });
            }
        },
        checktilledConnect() {
            if (this.user && this.user.role == "vendor" && !this.user.tilledConnected) {
                this.$store.commit("setMessage", "Please connect EzTimePay account");
            }
        }
    },
    async created() {
        await this.$store.dispatch("setting/refreshPayment");
    }
};
</script>
<style lang="less">
.account-tilled-connect {
    .connect-ctrls {
        display: flex;
        align-items: center;
        .md-button:first-child {
            margin-left: 0;
        }
        button {
            height: 36px;
        }
    }
    .connect {
        margin-bottom: 10px;
        // margin-left: 0;
        margin-right: 10px;
    }
    .conncet-success {
        &.md-button:not([disabled]).md-raised:not(.md-icon-button) {
            background-color: #6bb100;
            color: white;
            height: 35px;
            margin-bottom: 10px;
            // margin-left: 0;
            margin-right: 10px;
        }
    }
}
</style>
