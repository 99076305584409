<template>
    <div class="account-stripe-connect" v-if="user">
        <md-card style="max-width:1000px;margin:auto">
            <md-card-header>
                <h2>Stripe Connect</h2>
            </md-card-header>
            <md-card-content>
                <div v-if="user.stripeConnectId">
                    <label for>Connect Id:</label>
                    <span>{{ user.stripeConnectId }}</span>
                </div>
                <div class="connect-ctrls">
                    <md-button @click="jumpConnect" class="connect md-raised md-primary" v-if="showConnect">Connect Stripe</md-button>
                    <md-button class="conncet-success md-raised" v-if="this.user.stripeConnectId">Your account is connected with Stripe</md-button>
                    <md-button @click="jumpConnect" class="connect md-raised md-primary" v-if="showReConnect">ReConnect Stripe</md-button>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { vendorTypes } from "@/config";
export default {
    data() {
        return {
            vendorTypes
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            defaultHead: "user/defaultHead",
            paymentSetting: "setting/payment",
            error: "error",
            loading: "loading"
        }),
        showConnect() {
            return !this.user.stripeConnectId && this.user.role == "vendor" && !this.user.master_vendor_id;
        },
        showReConnect() {
            let { showReConnect } = this.$route.query;
            return showReConnect;
        }
    },
    watch: {},
    methods: {
        ...mapMutations({
            setUser: "user/setUser"
        }),
        ...mapActions({
            updateProfile: "user/updateProfile"
        }),
        jumpConnect() {
            const { value } = this.paymentSetting;
            if (value && value.stripe_client_id) {
                const client_id = value.stripe_client_id;
                let redirect_uri = location.href.split("#")[0];
                location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&redirect_uri=${redirect_uri}`;
            } else {
                this.$store.commit("setMessage", "Client Id not found");
            }
        },
        async connect(code) {
            let result = await this.$store.dispatch("crud/post", {
                api: "stripe/connect",
                data: {
                    code
                }
            });
            this.setUser({ ...this.user, ...{ stripeConnectId: result.data } });
            this.$router.push("/account/connect");
            location.reload();
        },
        checkStripeConnect() {
            if (this.user && this.user.role == "vendor" && !this.user.stripeConnectId) {
                this.$store.commit("setMessage", "Please connect stripe account");
            }
        }
    },
    async created() {
        let code = this.$route.query.code;
        await this.$store.dispatch("setting/refreshPayment");
        if (code) {
            await this.connect(code);
        }
    }
};
</script>
<style lang="less">
.account-stripe-connect {
    .connect-ctrls {
        display: flex;
        align-items: center;
        .md-button:first-child {
            margin-left: 0;
        }
        button {
            height: 36px;
        }
    }
    .connect {
        margin-bottom: 10px;
        // margin-left: 0;
        margin-right: 10px;
    }
    .conncet-success {
        &.md-button:not([disabled]).md-raised:not(.md-icon-button) {
            background-color: #6bb100;
            color: white;
            height: 35px;
            margin-bottom: 10px;
            // margin-left: 0;
            margin-right: 10px;
        }
    }
}
</style>
