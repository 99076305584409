<template>
    <div>
        <md-tabs>
            <md-tab md-label="Stripe" v-if="showStripe">
                <AccountStripeConnect />
            </md-tab>

            <md-tab md-label="EzTimePay" v-if="showTilled">
                <AccountTilledConnect />
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountStripeConnect from "./AccountStripeConnect";
import AccountTilledConnect from "./AccountTilledConnect";
export default {
    data() {
        return {
            tabIndex: 0
        };
    },
    components: {
        AccountStripeConnect,
        AccountTilledConnect
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasPermission: "user/hasPermission",
            paymentSetting: "setting/payment"
        }),
        showStripe() {
            return this.paymentSetting.value && this.paymentSetting.value.use_stripe;
        },
        showTilled() {
            return this.paymentSetting.value && this.paymentSetting.value.use_tilled;
        }
    },

    async created() {}
};
</script>

<style></style>
